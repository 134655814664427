@import "~bootstrap/scss/bootstrap";
@import './_shared.scss';
@import './custom.scss';


body, html {
    height: 100%;
}

body {
    margin: 0;
    background-color: color(cw-primary-darkest);
    
    #root {
        height: 100%;
        color: #fff;
        font-family: $font-family;
        font-weight: 1000;
    }
}

video.localPreview {
    // Transform video element to mirror camera image
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}