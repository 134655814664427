.wizard {
    height: 100%; 
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    overflow-x: hidden;
}

.wizard > div:nth-child(2) {    
    height: 85%;
    padding: 15px;
}

.wizard > div:nth-child(2) > div {    
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}
