$viewport-4: 400px;
$viewport-5: 576px;
$viewport-7: 768px;
$viewport-9: 992px;
$viewport-12: 1200px;

@mixin viewport-4 {
    @media (min-width: #{$viewport-4}) and (min-height: 500px) {
        @content;
    }
}

@mixin viewport-4-ls {
    @media (min-width: #{$viewport-4}) and (orientation: landscape) {
        @content;
    }
}

@mixin viewport-5 {
    @media (min-width: #{$viewport-5}) {
        @content;
    }
}

@mixin viewport-5-ls {
    @media (min-width: #{$viewport-5}) and (orientation: landscape) {
        @content;
    }
}

@mixin viewport-7 {
    @media (min-width: #{$viewport-7}) and (min-height: 500px) {
        @content
    }
}

@mixin viewport-7-ls {
    @media (min-width: #{$viewport-7}) and (orientation: landscape) {
        @content
    }
}

@mixin viewport-9 {
    @media (min-width: #{$viewport-7}) and (min-height: 800px) {
        @content
    }
}

@mixin viewport-12 {
    @media (min-width: #{$viewport-12}) {
        @content
    }
}

$colors: (
    cw-primary-darkest: #002e63,
    cw-primary-darker: #221f72,
    cw-primary-lighter: #0066a5,
    cw-primary-lightest: #5e87a1,
    nice-blue: #5caceb,
);

@function color($color-name) {
    @return map-get($colors, $color-name);
}

$font-family: 'Montserrat';
$borderGray: #ced4da;
