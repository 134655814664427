@import '../../../../scss/_shared.scss';

.actionsContainer {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 5px;

    @include viewport-5 {
        height: 100%;
        width: 10%;
        left: 0;
        flex-direction: column;
        justify-content: space-evenly;
    }

    @include viewport-7 {
        bottom: 10px;
        height: auto;
        width: 100%;
        flex-direction: row;
        justify-content: center;
    }
}

.actionButtons {
    margin: 10px;
    width: 45px;
    height: 45px;
    padding: 7px 12px;
    border-radius: 25px;
    font-size: 15px;
    line-height: 1.33;
    text-align: center;

    box-shadow: 0 0 10px 0.4px $borderGray;

    @include viewport-5 {
        font-size: 20px;
        width: 50px;
        height: 50px;
    }

    @include viewport-7 {
        margin: 10px 40px;
    }
}

.actionButtonHighlight,
.actionButtonHighlight:active,
.actionButtonHighlight:focus,
.actionButtonHighlight:hover {
     color: #ed1c23;
     border: 1px solid #ed1c23;
     box-shadow: 0 0 5px 1px #ed1c23;
}

.phoneIcon {
    transform: translateX(-1px);
}