.container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: 60px;
    height: 150px;
    margin: 30vh 0;
}

.text {
    font-size: 1.3rem;
}