@import '../../../../scss/_shared.scss';

.mainVideo {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
}

.mainVideo video {
    width: 100%;
}

.mainVideoHidden {
    display: none;
}