@import '../../../scss/_shared.scss';

.container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 20px;
}

.iconContainer {
    height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.container p {
    text-align: center;
}
