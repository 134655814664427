@import '../../../scss/_shared.scss';

.header {
  text-align: center;
}

.audioBtn {
    width: 60px;
    height: 60px;
    background: radial-gradient( rgba(0, 123, 255, 0.8) 60%, rgba(255, 255, 255, 1) 62%);
    border-radius: 50%;
    position: relative;
    display: block;
    margin: 30px auto;
    box-shadow: 0px 0px 25px 3px rgba(0, 123, 255, 0.8);
    border: none;
    outline: none;

    @include viewport-4 {
      height: 100px;
      width: 100px;
      margin: 70px auto;
    }

    @include viewport-5 {
      margin: 20px auto;
    }

    @include viewport-7 {
        margin: 100px auto;
    }

    @include viewport-9 {
      margin: 100px auto;
    }
  }
  
  /* pulse wave */
  .stopBtn:before {
    content: "";
    position: absolute;
    width: 150%;
    height: 150%;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation: pulsate1 2s;
    animation: pulsate1 2s;
    -webkit-animation-direction: forwards;
    animation-direction: forwards;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: steps;
    animation-timing-function: steps;
    opacity: 1;
    border-radius: 50%;
    border: 5px solid rgba(255, 255, 255, .75);
    top: -25%;
    left: -25%;
    background: rgba(198, 16, 0, 0);
  }
  
  @-webkit-keyframes pulsate1 {
    0% {
      -webkit-transform: scale(0.7);
      transform: scale(0.7);
      opacity: 1;
      box-shadow: inset 0px 0px 25px 3px rgba(255, 255, 255, 0.75), 0px 0px 25px 10px rgba(255, 255, 255, 0.75);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 0;
      box-shadow: none;  
    }
  }
  
  @keyframes pulsate1 {
    0% {
      -webkit-transform: scale(0.7);
      transform: scale(0.7);
      opacity: 1;
      box-shadow: inset 0px 0px 25px 3px rgba(255, 255, 255, 0.75), 0px 0px 25px 10px rgba(255, 255, 255, 0.75);
    }
    100% {
      -webkit-transform: scale(1, 1);
      transform: scale(1);
      opacity: 0;
      box-shadow: none;  
    }
  }

.playIcon {
    transform: translate(4px, 2px);
}

.stopIcon {
    transform: translate(0px, 2px);
}
