@import '../../../scss/_shared.scss';

.container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 20px;
}

.container p {
    @include viewport-7 {
        font-size: 1.4rem;
    }
}

@include viewport-7 {
    .header {
        font-size: 2.2rem;
    }

    .message {
        font-size: 1.5rem;
    }
}