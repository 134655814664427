@import '../../../scss/_shared.scss';

.container {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
}

.confirmText {
    max-width: 700px;
}