.header {
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo {
    height: 53px;
    margin: 10px;
}

.header span {
    font-size: 1.2rem;
    margin-top: 5px;
}

.container {
    height: 90%;
    max-height: 900px;
}

