.container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 20px;
}

.header {
    margin: 0 0 15px 0;
}

.browserList {
    display: flex;
    flex-direction: column;
}

.link {
    text-decoration: underline;
}
