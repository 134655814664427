@import '../../../scss/_shared.scss';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    justify-content: center;
    height: 230px;
    width: 230px;
    border : 2px solid #fff;
    border-radius: 50%;

    @include viewport-4 {
        height: 250px;
        width: 250px;
    }
}

.icon {
    margin-bottom: 10px;
}

.userText {
    width: 180px;
    text-align: center;
}