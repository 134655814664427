@import '../../../scss/_shared.scss';

.container {
    height: 100%;
    display: block;
    font-size: 0.8rem;
    padding: 20px;

    p {
        text-align: center;
    }

    @include viewport-4 {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;        
        align-items: center;        
        font-size: 1rem;
    }
}

.iconContainer {
    height: 110px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    font-size: 3em;

    @include viewport-4 {
        height: 140px;
        font-size: 4rem;
    }

    h4 {
        font-size: 1.2rem;
        padding: 10px;

        @include viewport-4 {
            font-size: 1.5rem;
        }
    }
}
