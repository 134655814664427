@import '../../../scss/_shared.scss';

.container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.link {
    text-decoration: underline;
}
