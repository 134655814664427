@import '../../../../scss/_shared.scss';

.myVideo {
    position: absolute;
    top: 5px;
    right: 5px;

    @include viewport-7 {
        top: 10px;
        right: 10px;
    }
}

.myVideoHidden {
    display: none;
}

.myVideo video {
    max-width: 75px;
    border: 0.5px solid $borderGray;
    border-radius: 5px;
    box-shadow: 0 0 10px 0.4px $borderGray;

    @include viewport-5 {
        max-width: 100px;
    }

    @include viewport-7 {
        max-width: 100px;
    }

    @include viewport-9 {
        max-width: 150px;
    }
}