@import '../../../scss/_shared.scss';

p {
    text-align: center;
}

.successText {
    text-align: center;

    @include viewport-7 {
        font-size: 2.1rem;
    }

    @include viewport-12 {
        font-size: 1.7rem;
    }
}

.deviceSummaryContainer {   
    width: 70vw;
    height: 300px;
    max-width: 270px;
    max-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.deviceSummary {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.deviceSummary p {
    font-size: 1.35rem;
}

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    margin-bottom: 10px;  
    font-size: 2rem;  

    @include viewport-4 {
        height: 70px;
        width: 70px;
        font-size: 3rem;
    }
}
