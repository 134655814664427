@import '../../../scss/_shared.scss';

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 20px;
}

.header {
    text-align: center;
}

.months {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: 20px auto 0px auto;
}

.month {
    margin: 7px;
    height: 5rem;
    width: 45%;
    background-color: color(nice-blue);
    font-size: 1.3em;

    @include viewport-4 {
        margin: 10px;
        height: 7rem;
        width: 40%;
    }

    @include viewport-5 {
        height: 4rem;
        width: 30%;
    }

    @include viewport-7 {
        height: 7rem;        
    }

    @include viewport-9 {
        height: 6rem
    }
}
