@import '../../../scss/_shared.scss';

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 20px;
}

.header { 
    text-align: center;
}

.days {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: 20px auto;

    @include viewport-12 {
        width: 1100px;
    }
}

.day {
    margin: 6px;
    height: 4.8rem;
    width: 5rem;
    background-color: #5caceb;
    font-size: 1.3em;

    @include viewport-4 {
        height: 5.2rem;
        width: 6.2rem;
    }

    @include viewport-7 {
        height: 5.5rem;
        width: 7.5rem;
    }
}
