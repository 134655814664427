@import '../../../scss/_shared.scss';

.container {
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 20px;

    @include viewport-12 {
        height: 70vh;
    }
}

.header {
    text-align: center;

    @include viewport-7 {
        font-size: 2.1rem;
    }

    @include viewport-12 {
        font-size: 1.7rem;
    }
}

.year {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

input {
    display: flex;
    justify-content: center
}

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance: textfield;
    }

input {    
    height: 4.5rem;
    width: 11rem;
    font-size: 4rem;
    text-align: center;

    // iOS fix
    padding: 0px;
    line-height: 4.5rem;
    // iOS fix

    @include viewport-7 {
        height: 6rem;
        width: 16rem;
        font-size: 6rem;
    }

    @include viewport-12 {
        height: 5rem;
        width: 13rem;
        font-size: 5rem;
    }
}

.btnNext {
    border-radius: 50%;
    height: 80px;
    width: 80px;
    font-size: 2.5em;
    padding: 0;

    @include viewport-7 {
        height: 100px;
        width: 100px;
        font-size: 3rem;
    }

    @include viewport-12 {
        height: 90px;
        width: 90px;
    }
}