@import '../../../scss/_shared.scss';

.header {
    text-align: center;
}

.video {
    max-width: 90%;
    max-height: 150px;
    padding: 10px;

    @include viewport-4 {
        max-height: 250px;;
    }

    @include viewport-4-ls {
        max-height: 100px;;
    }

    @include viewport-5 {
        max-height: 220px;
    }

    @include viewport-5-ls {
        max-height: 120px;
    }

    @include viewport-7 {
        max-height: 300px;
    }

    @include viewport-7-ls {
        max-height: 250px;
    }

    @include viewport-9 {
        max-height: 350px;
    }
}