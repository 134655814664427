@import '../../../scss/_shared.scss';

.container {
    height: 400px;
    max-width: 90vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 30px;

    @include viewport-4 {
        height: 500px;
    }
}

.header {
    text-align: center;
}

.wave {
     height: 30vh;
     padding: 10px;
     display: flex;
     justify-content: center;
}

.wave canvas {
    width: 100%;
}
