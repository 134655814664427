.nav {
    display: flex;
    justify-content: space-around;
    width: 300px;
    margin-bottom: 20px;
}

.btn {
    height: 60px;
    width: 120px;
    border-radius: 4px;
    font-size: 1.3em;
}